/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useRef, useEffect } from 'react';
import { useTable } from 'react-table';
import PersonalData from '#components/finances/dashboards/reports/cells/PersonalData';
import BankAccountData from '#components/finances/dashboards/reports/cells/BankAccountData';
import CompanyData from '#components/finances/dashboards/reports/cells/CompanyData';
import BasicMonetaryValue from '#components/finances/dashboards/reports/cells/BasicMonetaryValue';
import TotalToReceive from '#components/finances/dashboards/reports/cells/TotalToReceive';
import EngDateFormat from '#services/EngDateFormat'
import i18n from '#translate/i18n';

function PayrollTable({ data }) {
  const bodyTableRef = useRef(null);
  const headerTableRef = useRef(null);

  useEffect(() => {
    const bodyTable = bodyTableRef.current;

    const handleScroll = () => {
      if (headerTableRef.current) {
        headerTableRef.current.scrollLeft = bodyTable.scrollLeft;
      }
    };

    bodyTable.addEventListener('scroll', handleScroll);

    return () => {
      bodyTable.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const totalReceiveText = () => {
    const dateDoc = document.getElementById('component-range-date-input')
    const dateValue = dateDoc ? dateDoc.value : null
    const currentDates = dateValue === '' ? [] : dateValue.split(' - ')
    const currentStart = EngDateFormat(currentDates[0])
    const currentEnd = EngDateFormat(currentDates[1])
    const currentStartDate = new Date(`${currentStart}T00:00`)
    const currentEndDate = currentEnd && new Date(`${currentEnd}T00:00`)
    if (currentEndDate && (currentStartDate.getMonth() !== currentEndDate.getMonth())) {
      return <span className='flex w-[155px]'>{i18n.t('reportDashboard.payroll.total_to_receive_period')}</span>
    } else {
      return <span className='flex w-[155px]'>{i18n.t('reportDashboard.payroll.total_to_receive')}</span>
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: () => <span className='flex ml-[5px] w-[338px]'>{i18n.t('reportDashboard.payroll.personal_data.title')}</span>,
        accessor: 'personal_data',
        Cell: PersonalData,
      },
      {
        Header: () => <span className='flex w-[287px]'>{i18n.t('reportDashboard.payroll.bank_data.title')}</span>,
        accessor: 'bank',
        Cell: BankAccountData,
      },
      {
        Header: () => <span className='flex w-[323px]'>{i18n.t('reportDashboard.payroll.company_data.title')}</span>,
        accessor: 'company',
        Cell: CompanyData,
      },
      {
        Header: () => totalReceiveText(),
        accessor: 'total_to_receive',
        Cell: TotalToReceive,
      },
      {
        Header: () => <span className='flex w-[114px] justify-center'>{i18n.t('reportDashboard.payroll.total_invoice')}</span>,
        accessor: 'total_invoice',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[114px]' />,
      },
      {
        Header: () => <span className='flex w-[187px]'>{i18n.t('reportDashboard.payroll.total_benefits_card')}</span>,
        accessor: 'total_benefits_card',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[187px]' />,
      },
      {
        Header: () => <span className='flex m-auto w-32'>{i18n.t('reportDashboard.payroll.salary')}</span>,
        accessor: 'salary',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[32]' />,
      },
      {
        Header: () => <span className='flex w-[104px] justify-center' >{i18n.t('reportDashboard.payroll.healthcare')}</span>,
        accessor: 'healthcare',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[104px]' />,
      },
      {
        Header: () => <span className='flex w-[179px] justify-center'>{i18n.t('reportDashboard.payroll.vacation')}</span>,
        accessor: 'vacation',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[179px]' />,
      },
      {
        Header: () => <span className='flex w-[130px] justify-center'>{i18n.t('reportDashboard.payroll.year_end_bonus')}</span>,
        accessor: 'year_end_bonus',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[130px]' />,
      },
      {
        Header: () => <span className='flex w-[131px] justify-center'>{i18n.t('reportDashboard.payroll.salary_readjustment')}</span>,
        accessor: 'salary_readjustment',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[131px]' />,
      },
      {
        Header: () => <span className='flex w-[121px] justify-center'>{i18n.t('reportDashboard.payroll.meal')}</span>,
        accessor: 'meal',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[121px]' />,
      },
      {
        Header: () => <span className='flex w-[115px] justify-center'>{i18n.t('reportDashboard.payroll.home_office')}</span>,
        accessor: 'home_office',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[115px]' />,
      },
      {
        Header: () => <span className='flex w-[115px] justify-center'>{i18n.t('reportDashboard.payroll.onboarding')}</span>,
        accessor: 'onboarding',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[115px]' />,
      },
      {
        Header: () => <span className='flex w-[105px] justify-center'>{i18n.t('reportDashboard.payroll.extra_rewards')}</span>,
        accessor: 'extra_rewards',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[105px] justify-center' />,
      },
      {
        Header: () => <span className='flex w-[145px] justify-center'>{i18n.t('reportDashboard.payroll.dismissal_installment')}</span>,
        accessor: 'dismissal_installment',
        Cell: ({ value }) => <BasicMonetaryValue value={value} divStyle='w-[145px]' />,
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <div className="w-full max-w-fit">
      <div
        ref={headerTableRef}
        className={`sticky top-0 z-[1] bg-white overflow-hidden`}
      >
        <table {...getTableProps()} border='1' id='report-table'
          className='bg-white w-full table-auto box-border p-0 rounded drop-shadow-[0_4px_6px_rgba(15,23,42,0.08)]'
        >
          <thead className='contents py-[9px] px-2 bg-light-gray shadow-[inset_0_-1px_0_rgba(212,219,223,1)]'>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='whitespace-nowrap bg-light-gray'>
                {headerGroup.headers.map((column) => {
                  const headerFixedPosition = 'left-0 z-[160]'
                  const columnPositionStyle = column.id === 'personal_data' ? headerFixedPosition : 'z-[150]'
                  return (
                    <th
                      scope='col'
                      className={`sticky top-0 bg-light-gray shadow-[inset_0_-1px_0_rgba(212,219,223,1)] h-[52px] font-sans
                        not-italic font-semibold text-xs text-default-gray-2 uppercase ${columnPositionStyle} pt-4 px-2.5 inline-block`}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
        </table>
      </div>
      <div
        ref={bodyTableRef}
        className={"h-[calc(100%-56px)] w-full overflow-auto"}
      >
        <table>
          <tbody {...getTableBodyProps()} className='bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)]'>
            {data.length > 0 && rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className='py-0 px-2 mb-4 bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)]'>
                  {row.cells.map((cell) => {
                    const rowFixedPosition = 'sticky left-0 bg-white shadow-[inset_0_-1px_0_rgba(212,219,223,1)] pl-[15px]'
                    const columnPositionStyle = cell.getCellProps().key.match(/personal_data/) ? rowFixedPosition : ''
                    return (
                      <td {...cell.getCellProps()} className={`px-2.5 py-0 whitespace-nowrap ${columnPositionStyle}`}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PayrollTable;
