import React from 'react';
import i18n from '#translate/i18n';

export default function CompanyData({ row }) {
  const allData = row.original ? row.original : {}
  const id = allData.id
  return (
    <div className='flex items-stretch h-[180px] pr-2.5 pt-2 w-[323px]'>
      <div className='flex-shrink-0'>
        <div className='h-5 font-sans not-italic font-semibold text-sm text-default-gray-2'>
          {i18n.t('reportDashboard.payroll.company_data.cnpj')} <span id={`report_company_cnpj_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.company_cnpj}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.company_data.corporate_name')} </span>
          <span id={`report_company_name_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.company_corporate_name}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.company_data.type_corporate')} </span>
          <span id={`report_company_type_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.company_type_corporate}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.company_data.city')} </span>
          <span id={`report_company_city_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.company_city}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.company_data.state_registration')} </span>
          <span id={`report_company_state_register_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.company_state_registration}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.company_data.municipal_registration')} </span>
          <span id={`report_company_city_register_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{allData.company_municipal_registration}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-default-gray-2'>{i18n.t('reportDashboard.payroll.company_data.collect_iss')} </span>
          <span id={`report_company_iss_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{i18n.t(`reportDashboard.payroll.collect_iss.${allData.company_collect_iss}`)}</span>
        </div>
      </div>
    </div>
  );
}
