/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useFormContext, useWatch, get } from "react-hook-form";
import DivisionTitle from "../../DivisionTitle";
import HobbySearch from "../HobbySearch";
import HobbyList from "../HobbyList";

export default function HobbyFields({ model }) {
  const fieldName = "hobby_ids";
  const inputName = model ? `${model}.${fieldName}` : fieldName;
  const { register, setValue, formState: { errors } } = useFormContext();
  const userHobbies = useWatch({ name: inputName });
  const [hobbies, setHobbies] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const fieldError = get(errors, 'personal_preference.hobby_ids');

  const availableHobbies = hobbies.filter((hobby) => {
    const selectedHobbiesIds = selectedHobbies.map((hobby) => hobby.id);

    return !selectedHobbiesIds.includes(hobby.id);
  });

  useEffect(() => {
    fetch("/api/v1/hobbies").then((response) => response.json()).then((parsedResp) => setHobbies(parsedResp))
  }, []);

  useEffect(() => {
    register(inputName)
  }, [register]);

  useEffect(() => {
    const newSelectedHobbies = hobbies.filter(({ id }) =>
      userHobbies && userHobbies.includes(id)
    );

    sortHobbies(newSelectedHobbies);
    setSelectedHobbies(newSelectedHobbies);
  }, [userHobbies, hobbies]);

  const handleSelectHobby = ({ selectedHobby: { id } }) => {
    const list = new Set([...userHobbies, id]);
    setValue(inputName, [...list]);
  };

  const handleRemoveHobby = ({ selectedHobby: { id } }) => {
    const list = userHobbies.filter((hobby_id) => hobby_id !== id);
    setValue(inputName, [...list]);
  };

  function sortHobbies(hobbies) {
    hobbies.sort((hobby, otherHobby) =>
      String(hobby.name).localeCompare(otherHobby.name)
    );
  }

  return (
    <>
      <DivisionTitle title={"HOBBIES"} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <HobbySearch
          availableHobbies={availableHobbies}
          handleSelectHobby={handleSelectHobby}
          classNameSearchDiv={'mt-[5px] space-y-2'}
        />

        <div>
          <HobbyList
            selectedHobbies={selectedHobbies}
            handleRemoveHobby={handleRemoveHobby}
            requiredField={false} //true
            fieldError={fieldError}
          />
          { fieldError && (
            <span
              name={"validationError"}
              className={"items-center font-medium tracking-wide text-red-500 text-xs mt-1"}
              role="alert"
              id={`hobby_list_error`}
            >
              {fieldError.message}
            </span>
          )}
        </div>
      </div>
    </>
  );
}