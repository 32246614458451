import React from "react"
import i18n from "#translate/i18n";

import DivisionLine from '#components/formComponents/DivisionLine'
import Message from "#components/navigation/notifications/Message";

export default function RenderNotifications({ readMessagesData, unreadMessagesData, setRefreshMessages }) {
  return (
    <div className="overflow-y-auto h-[calc(100vh-170px)]" id="messages_notifications" >
      <p className="text-disabled-dark-gray text-sm font-medium p-2 ml-3">{i18n.t('notification.unread')}</p>
      {unreadMessagesData?.map((message, index) => (
        <div key={index}>
          <Message messageData={message} alreadyRead={false} setRefreshMessages={setRefreshMessages} />
        </div>
      ))}

      <DivisionLine customStyle='m-0 p-0' />
      <p className="text-disabled-dark-gray text-sm font-medium p-2 ml-3">{i18n.t('notification.read')}</p>
      {readMessagesData?.map((message, index) => (
        <div key={index}>
          <Message messageData={message} alreadyRead={true} setRefreshMessages={setRefreshMessages} />
        </div>
      ))}
    </div>
  )
}
