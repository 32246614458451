import React from "react"
import i18n from "#translate/i18n"
import { Trans } from "react-i18next"
import moment from "moment"
import RoundPicture from "#components/user/RoundPicture";
import ImgProfile from "#images/profile_picture.svg";
import BirthDateBG from "#images/home_page/upcoming_events/anniversary.svg"
import CompanyAnniversaryBG from "#images/home_page/upcoming_events/company_anniversary.svg"
import VacationBG from "#images/home_page/upcoming_events/vacation.svg"
import ParentalLeaveBG from "#images/home_page/upcoming_events/parental_leave.svg"

export default function EventComponent({ eventData }) {
  const eventName = eventData?.event_name

  const eventDisplayData = () => {
    const presentedData = eventData.event_data

    if (["birth_date", "return_vacation", "return_parental_leave"].includes(eventName)) {
      const date = Object.values(presentedData)[0]

      return moment(date).locale("pt-br").format("DD/MM")
    } else if (["start_vacation", "start_parental_leave"].includes(eventName)) {
      const startDate = moment(presentedData?.event_date)
      const returnDate = moment(presentedData?.return_date)

      return (
        <Trans
          i18nKey={i18n.t("homePage.upcomingEvents.start_event_description")}
          values={{ startDay: startDate.date(), startMonth: startDate.month() + 1, returnDay: returnDate.date(), returnMonth: returnDate.month() + 1}}
        />
      )
    } else {
      const date = moment(presentedData?.event_date)

      return (
        <Trans
          i18nKey={i18n.t("homePage.upcomingEvents.company_anniversary_description")}
          values={{ day: date.date(), month: date.month() + 1, years_in_the_company: presentedData.years_in_the_company}}
        />
      )
    }
  }
  const eventBackground = {
    birth_date: BirthDateBG,
    company_anniversary: CompanyAnniversaryBG,
    start_vacation: VacationBG,
    return_vacation: VacationBG,
    start_parental_leave: ParentalLeaveBG,
    return_parental_leave: ParentalLeaveBG,
  }

  return (
    <div id={`users_${eventName}`} className={`w-full h-full ${eventBackground[eventName] && "rounded border border-solitude border-[1px] border-solid bg-white"}`} >
      {eventBackground[eventName] && eventData && (
        <div className="h-full flex flex-col relative" >
          <img
            className="rounded-t h-auto max-h-[98%] w-full"
            src={eventBackground[eventName]}
            alt={"event-bg"}
          />
          <div className="absolute w-full h-full top-11">
            <RoundPicture
              divStyle="w-20 h-20 absolute z-50 mx-auto left-0 right-0"
              imgStyle="group-hover:brightness-50 object-cover border-2 border-white drop-shadow-[0_0.5px_0.5px_rgba(0,0,0,0.25)]"
              alt="face_picture"
              src={eventData.face_picture?.url || ImgProfile}
            />
            <div className="w-full bottom-[49px] bg-white top-[49px] absolute" />
            <div className="flex flex-col items-center absolute w-full gap-0.5 bottom-[49px] top-[86px]">
              <p className="pt-0 w-full font-sans text-xl/6 font-semibold text-center text-midnight" >
                {eventData.full_name}
              </p>
              <p className="font-sans text-sm font-medium text-center text-rebase-blue" >
                {i18n.t(`homePage.upcomingEvents.${eventName}`)}
              </p>
              <p className="font-sans text-sm font-normal text-left text-soft-light-gray" >
                {eventDisplayData()}
              </p>
              { eventData.client_logo?.url && <img src={eventData.client_logo.url} alt="client" className="w-fit py-2.5 my-2.5"/> }
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
