import React from "react";
import i18n from "#translate/i18n";
import PTBRDateFormat from '#services/PTBRDateFormat';

export default function CompanyHistoric({ userData }) {
  const companyHistoricData = {
    graduate_class_name: userData.graduate_class_name,
    hiring_date: userData.hiring_date,
    current_remuneration: +userData.current_remuneration,
    initial_remuneration: +userData.company_history?.initial_remuneration
  }

  return (
    <div
      className="text-info-gray font-sans text-sm leading-[26px] tracking-normal text-left p-4 px-5" 
    >
      <p>
        <span className="font-bold">
          {i18n.t('userProfile.company_historic.hiring_date')}:
        </span>{" "}
        {PTBRDateFormat(companyHistoricData.hiring_date)}
      </p>
      <p className={companyHistoricData.graduate_class_name ? '' : 'hidden'}>
        <span className="font-bold">
          {i18n.t('userProfile.company_historic.graduate_class_name')}:
        </span>{" "}
        {`${companyHistoricData.graduate_class_name}`}
      </p>
      <p>
        <span className="font-bold">
          {i18n.t('userProfile.company_historic.initial_remuneration')}:
        </span>{" "}
        {companyHistoricData.initial_remuneration?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
      </p>
      <p>
        <span className="font-bold">
          {i18n.t('userProfile.company_historic.current_remuneration')}:
        </span>{" "}
        {companyHistoricData.current_remuneration?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
      </p>
    </div>
  );
}
