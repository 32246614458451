const contactFields = [
  {
    personal_email: "",
    corporate_email: "",
    DDD_landline: "",
    landline: "",
    DDD_phone_number: "",
    phone_number: "",
    primary: true,
  },
];

const primaryEmergencyContactFields = {
  name: "",
  relationship: "",
  DDD_landline: "",
  landline: "",
  DDD_phone_number: "",
  phone_number: "",
  observations: "",
  primary: true,
}

const emergencyContactFields = {
  name: "",
  relationship: "",
  DDD_landline: "",
  landline: "",
  DDD_phone_number: "",
  phone_number: "",
  observations: "",
  primary: false,
}

const emergencyContactsFields = [
  primaryEmergencyContactFields,
  emergencyContactFields,
];


const addressFields = {
  zip_code: "",
  street: "",
  house_number: "",
  complement: "",
  country: "",
  state: "",
  city: "",
  neighborhood: "",
  reference_point: "",
  alternative: "",
  empty_complement: false
};

const deliveryAddressFields = {
  zip_code: "",
  street: "",
  house_number: "",
  complement: "",
  country: "",
  state: "",
  city: "",
  neighborhood: "",
  reference_point: "",
  empty_complement: false
};

const bankAccountFields = [
  {
    bank_id: "",
    agency_number: "",
    account_number: "",
    account_type: "",
    pix_key: "",
    beneficiary: "",
    beneficiary_name: "",
    rg_of_holder: "",
    beneficiary_document_number: "",
    account_status: "",
  },
];

const companyInformationFields = {
  corporate_name: "",
  cnpj: "",
  type_corporate: "",
  state_registration: "",
  municipal_registration: "",
  empty_municipal_registration: false,
  collect_iss: "",
  owner_data: "",
  email: "",
  zip_code: "",
  street: "",
  neighborhood: "",
  city: "",
  state: "",
  house_number: "",
  complement: "",
  country: "",
  empty_complement: false
};

const userFields = {
  full_name: "",
  has_social_name: "",
  social_name: "",
  nickname: "",
  pronouns: "",
  lgbtqia_plus: "",
  marital_status: "",
  rg: "",
  cpf: "",
  birth_date: "",
  gender_identity: "",
  gender_description: "",
  health_notes: "",
  general_notes: "",
  allergies: "",
  hiring_date: "",
  sharing_home_with: "",
  race_color: "",
  medical_follow_up: "",
  special_medical_condition: "",
  human_children: "",
  graduate_class_id: "",
  rg_front: "",
  rg_back: "",
  residence_proof: "",
  cpf_front: "",
  cpf_back: "",
  service_contract: "",
  cnpj_card: "",
  lgpd_contract: "",
  image_usage_agreement: "",
  current_remuneration: ""
};

const pictureFields = {
  face_picture: "",
  body_picture: "",
};

const companyHistoryRecordFields = [
  {
    project_name: "",
    client_id: "",
    squad_name: "",
    start_date: "",
    end_date: "",
    adjourning_motivation: "",
    tools_and_permissions: "",
  },
];

const companyHistoryFields = {
  company: "",
  work_field: "",
  initial_remuneration: "",
  mattermost_username: "",
  github_user: "",
  has_equipment: "",
  service_tag: "",
  company_assets: "",
  client_assets: "",
};

const personalPreferenceFields = {
  hobby_ids: [],
  preferred_movies_series: "",
  preferred_bands_singers: "",
  best_trip: "",
  wish_list: "",
  personal_collections: "",
  likes_social_media: "",
  about_you: "",
  favorite_color: "",
  day_night_preference: "",
  preferred_sport: "",
  preferred_club: "",
  preferred_videogame: "",
  preferred_foods: "",
  preferred_beers: "",
  preferred_juices: "",
  preferred_fruits: "",
  preferred_beverages: "",
  avoided_foods: "",
};

const clothingFields = {
  t_shirt_size: "",
  sweatshirt_size: "",
  pants_size: "",
};

const descendantFields = {
  full_name: "",
  birthdate: "",
}

const descendantsFields = [
  descendantFields
];

const newUserFields = {
  address: addressFields,
  bank_accounts: bankAccountFields,
  clothing: clothingFields,
  company_history: companyHistoryFields,
  company_history_records: companyHistoryRecordFields,
  company_information: companyInformationFields,
  contacts: contactFields,
  delivery_address: deliveryAddressFields,
  emergency_contacts: emergencyContactsFields,
  personal_preference: personalPreferenceFields,
  picture: pictureFields,
  user: userFields,
  descendants: descendantsFields,
};

export {
  addressFields,
  bankAccountFields,
  clothingFields,
  companyHistoryFields,
  companyHistoryRecordFields,
  companyInformationFields,
  contactFields,
  deliveryAddressFields,
  primaryEmergencyContactFields,
  emergencyContactFields,
  emergencyContactsFields,
  personalPreferenceFields,
  pictureFields,
  userFields,
  descendantsFields,
  descendantFields,
  newUserFields,
};
